import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/CA_Certificate/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import DangerBox from 'components/Web_User_Interface/1440p_Series/Network/CA_Certificate/DangerBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // CA Certificate",
  "path": "/Web_User_Interface/1440p_Series/Network/CA_Certificate/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "You want to use your own SSL certificate for your own DDNS domain? Not a problem! Generate a self signed certificate and upload the certificate ( X.509 certificate ) and key ( Private RSA Key ) - combined in a single *.pem file. Please also check the Custom CA Store  if you want to use a certificate that is signed by a Certificate Authority - e.g. a certificate generated by Let's Encrypt. Please note that if you don't upload a certificate you camera will use the CA certificate that comes with your personal INSTAR DDNS Address - either way, your encrypted communication will be secure!",
  "image": "./WebUI_1440p_SearchThumb_Network_IP_Configuration.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // CA Certificate' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='You want to use your own SSL certificate for your own DDNS domain? Not a problem! Generate a self signed certificate and upload the certificate ( X.509 certificate ) and key ( Private RSA Key ) - combined in a single *.pem file. Please also check the Custom CA Store  if you want to use a certificate that is signed by a Certificate Authority - e.g. a certificate generated by Let`s Encrypt. Please note that if you don`t upload a certificate you camera will use the CA certificate that comes with your personal INSTAR DDNS Address - either way, your encrypted communication will be secure!' image='/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Netzwerk/CA_Zertifikat/' locationFR='/fr/Web_User_Interface/1440p_Series/Network/CA_Certificate/' crumbLabel="CA" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/404189102b97020111da00e9ac34f327/d53ff/1440p_WebUI_Network_CA_Certificate.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACHklEQVQoz3WSOU8UcRiH+RoUFCqzwg5LLDBE95rZY/ac4z/HziGHycbdICsJrAgrhoCJEQsbLA1rSSINhYXR0sRYWfoF/CCP2QPQEIsn71s9+f3evBOJRAJZlpHnEsxIN0k761S3+ijtY5T2O9KtKzLjmW0do3f7LJqPSd1fZH5+nsnJSaamppgYyOLxOPLcHNKtG2jhBisHp/i7J/i9D3i7/Ws0dk5YPTylsrxDMV8imVKZuR0nkbjDxOzs7Egoy8RiMZRMksgzaYg6vl0nGKIPp3+56zhmGatUxa00McoexXwBVSlcF6pqjoYfYDvuf3EcF+G4LHkRL6MV2g8syqUM6ZQyEg5veJFQUYjCkCgaEBFGEa7rYtv2vwzEls5RN6S7rlGrZEgnlauEA2KSRFZRsdwQw/Hx/IAg8AmCAM/zxjIxFOnVEp3OOt8+f+SgI8gpKfJK9q/K8TjTkkQxp/C8Jeg1dSLPwrQEQgjERTIvpNHcoh602dvf5/eP75wf9tDUsfAi3aDytBSjXMjy/plJf7tGMzQxrJFIWDqirmF5IcbmW7S1V2xtPuXnl0+cvTngoa6QTGVHb3N5w3HlitmgULMxhY3jONjCwlndwH7ymqXlDofFHDuqwou1Fr++nnN21GN3WUMr5q8SDqpLkkQmk8ERFpahYxg6umGg12sYYQvz0R6NoMV2uUq3ZuKUNBqGjl0qkr+3wOLdBf4A3quB/Kek5dcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/404189102b97020111da00e9ac34f327/e4706/1440p_WebUI_Network_CA_Certificate.avif 230w", "/en/static/404189102b97020111da00e9ac34f327/d1af7/1440p_WebUI_Network_CA_Certificate.avif 460w", "/en/static/404189102b97020111da00e9ac34f327/7f308/1440p_WebUI_Network_CA_Certificate.avif 920w", "/en/static/404189102b97020111da00e9ac34f327/ca993/1440p_WebUI_Network_CA_Certificate.avif 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/404189102b97020111da00e9ac34f327/a0b58/1440p_WebUI_Network_CA_Certificate.webp 230w", "/en/static/404189102b97020111da00e9ac34f327/bc10c/1440p_WebUI_Network_CA_Certificate.webp 460w", "/en/static/404189102b97020111da00e9ac34f327/966d8/1440p_WebUI_Network_CA_Certificate.webp 920w", "/en/static/404189102b97020111da00e9ac34f327/55b8e/1440p_WebUI_Network_CA_Certificate.webp 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/404189102b97020111da00e9ac34f327/81c8e/1440p_WebUI_Network_CA_Certificate.png 230w", "/en/static/404189102b97020111da00e9ac34f327/08a84/1440p_WebUI_Network_CA_Certificate.png 460w", "/en/static/404189102b97020111da00e9ac34f327/c0255/1440p_WebUI_Network_CA_Certificate.png 920w", "/en/static/404189102b97020111da00e9ac34f327/d53ff/1440p_WebUI_Network_CA_Certificate.png 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/404189102b97020111da00e9ac34f327/c0255/1440p_WebUI_Network_CA_Certificate.png",
              "alt": "Web User Interface - 1440p Series - Network SSL Certificate",
              "title": "Web User Interface - 1440p Series - Network SSL Certificate",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1440p-web-user-interface"
        }}>{`1440p Web User Interface`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#network-menu"
            }}>{`Network Menu`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#format"
            }}>{`Format`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mqtt-ftps-and-https-alarmserver"
            }}>{`MQTT, FTPS and HTTPS Alarmserver`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#take-matters-into-your-own-hands"
            }}>{`Take Matters into your own Hands`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#mosquitto-broker"
                }}>{`Mosquitto Broker`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#ca-certificates"
                }}>{`CA Certificates`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#server-certificates"
                }}>{`Server Certificates`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#client-certificates"
                }}>{`Client Certificates`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#connect-your-instar-ip-camera"
                }}>{`Connect your INSTAR IP Camera`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "format",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#format",
        "aria-label": "format permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Format`}</h2>
    <p>{`The CA certificate is usually provided by your certificate authority - e.g. you can use the free service from `}<a parentName="p" {...{
        "href": "https://letsencrypt.org/"
      }}>{`Let's Encrypt`}</a>{` and get the CA certificate from their website - for example, the current root certificate is the `}<a parentName="p" {...{
        "href": "https://letsencrypt.org/certificates/"
      }}>{`lets-encrypt-r3.pem`}</a>{`. Generate the `}<strong parentName="p">{`X.509 Certificate`}</strong>{` and `}<strong parentName="p">{`Private RSA Key`}</strong>{` with a program like `}<a parentName="p" {...{
        "href": "https://certbot.eff.org/instructions"
      }}>{`certbot`}</a>{` and upload them in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/SSL_Certificate/"
      }}>{`SSL Certificate menu`}</a>{`. Then upload the Let's Encrypt root certificate here.`}</p>
    <p>{`The correct formatting is important, otherwise the camera will not accept the digital certificate. The camera uses certificates in PEM format. The certificate file must therefore have the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.pem`}</code>{` file extension. The content of the PEM file will look similar to the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`BEGIN CERTIFICATE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`
MIIFoTCCA4mgAwIBAgIUUuUOKXSIIZ8q9/9Bo+CX529MQKMwDQYJKoZIhvcNAQEL
BQAwYDELMAkGA1UEBhMCQVUxEzARBgNVBAgMClNvbWUtU3RhdGUxITAfBgNVBAoM
GEludGVybmV0IFdpZGdpdHMgUHR5IEx0ZDEZMBcGA1UEAwwQbmFubzE5LmZyaXR6
LmJveDAeFw0yMjA0MDcwNjM0NDZaFw0yNzA0MDYwNjM0NDZaMGAxCzAJBgNVBAYT
AkFVMRMwEQYDVQQIDApTb21lLVN0YXRlMSEwHwYDVQQKDBhJbnRlcm5ldCBXaWRn
aXRzIFB0eSBMdGQxGTAXBgNVBAMMEG5hbm8xOS5mcml0ei5ib3gwggIiMA0GCSqG
SIb3DQEBAQUAA4ICDwAwggIKAoICAQDaEr8NaGrgLuZodIfNZpPEIq8rmB+0D9NH
rB+S0RpaYCSilolR3KMqiP70zzqLdBfK7b3IYgj4etQAx1LZaGqp0xsRDkI8qA56
oiEaAO4Rjc/PME0ra6Ygqvsiz2S1LmeDKcNzPNk+DH7k0pXJK4RLmtCm6oGpREVv
FGjPrTOr8DmEIANzm/cJkZ8B33Z0qHXR9hMShlb63D1V1IaIil9dcygs6lxTiJHZ
wxc2ro3Z9oygV9R8Pw7p25GisXNIhJc6vsWwKbiCEIHvkz+c6Ap9VFqaKf3yp2CT
L7txvGI1yfdM88i2jNqVumotsAM4o5CFKzG3i5qZoioRnxLV757aMEymcQZEX3Oj
LEeojD/Nhry0A5xQ3CSiNSHuxmdMvM6tqVfmoEWrP4MM+aE8BiSu4e9HSk6wbhFr
rMZ7+1lYcP2o0adQqzNH/vpveZ2kxmly556BlZJHorGIALbU4D6P3UB3Iq7s9vl+
42OXnLQmQk6EaNbU57xhdA/UIcvfi2EAOsNW3gdj2KtJSONu18+qSrFFBn6lvnK7
1Smecyb20uYSYxTOnl7mOB7bhO+1xa7Kz06LObiSDta80eCMPilEAgQDemWA3xN3
CS2SWf7XFQlS8cKQgTf93q8RX7IOz3aELDQNPsd0z9TcnaGHvTVQjxCnmlF47qfJ
Yzo+QKHgEwIDAQABo1MwUTAdBgNVHQ4EFgQUKl+1mdOgymWy90NODB6LToHqeOIw
HwYDVR0jBBgwFoAUKl+1mdOgymWy90NODB6LToHqeOIwDwYDVR0TAQH/BAUwAwEB
/zANBgkqhkiG9w0BAQsFAAOCAgEAgk9hblrOir3+afRpjEYwj78Sw07/nnYxkDCv
5dj9mS/uiFYO4N5A75MumOFRCG2PBx7R7HduLrj8i1VRBse6Ftptx8kM+VnSLI8o
t5x2LfLm6qXnuTux13uvmDKuGZnZPE1VYMsGLbQXh1HVxqmKoMl8mCqDrb0na+2z
TIAaMW7Kb8citp2TPoWiD0gmxoz+Hk4UKH00XcRZ/KR81rz1Ifhj2qRPFPO5QKeU
SGIhKRtrEGeahGxTlXZDjq1J3yzAZNIWXwYaM4TmFju8Lhpu4NHNPFHCIN7W3f4S
g5WzKZrmo4co4guZlE9plZOkax3SG5abtWqvrwF2ZjPcmJrAH3GoKF/0/9aek5QR
A6trqEo/tlaPFacj92mkYN0fW7E8ITtqQJylQnpneq0yvcYNE3Ticv62nGlvQUec
rkMFs/BiRxSCI7uOCkFnf3maqQdul3apO5JjVvYNZSP1v0yHVNauYRkMwClYTQ2K
9NfeM45bWSE9DWwz3D4wRA4XfOko2+LGIiZXxpMP41os14Eng/XrU6BGwgHNvckp
VjIAPOka4bYGIu3RJIPF4UL5FppMpJWl7ZIubN27avAguIXZOHmL1u8UDqM8s5H3
3BCPiga2pC+7gllABixNSDhS1facQJ24wDXESyX9mjn8cGKBXF3onRS+/I0IpZ37
bBMdN+A=
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`END CERTIFICATE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span></code></pre></div>
    <DangerBox mdxType="DangerBox" />
    <h2 {...{
      "id": "mqtt-ftps-and-https-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-ftps-and-https-alarmserver",
        "aria-label": "mqtt ftps and https alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT, FTPS and HTTPS Alarmserver`}</h2>
    <p>{`If you upload a private certificate here, it will also be used for the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`INSTAR MQTT Broker`}</a>{`, the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTPS Alarmserver`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/FTP/"
      }}>{`FTPS Service`}</a>{` beside the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/IP_Configuration/"
      }}>{`HTTPS Connection`}</a>{` over your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/Remote_Access/"
      }}>{`INSTAR DDNS address`}</a>{`.`}</p>
    <p>{`As mentioned above, if you don't upload a certificate you camera will use the CA certificate that comes with your personal `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/Remote_Access/"
      }}>{`INSTAR DDNS address`}</a>{`. Currently, those certificates are issued by the company `}<a parentName="p" {...{
        "href": "https://godaddy.com"
      }}>{`GoDaddy`}</a>{`. To be able to connect to your camera using the TLS encryption, both devices need to "know" the `}<a parentName="p" {...{
        "href": "https://certs.godaddy.com/repository"
      }}>{`GoDaddy Root Certificate`}</a>{`. But if your computers or smartphones operating system is not too outdated they will have a copy and your web browser won't complain when accessing your camera's web user interface.`}</p>
    <p>{`Things become more difficult if you want to connect an IoT device using the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/FTP/"
      }}>{`FTPS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTPS`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTTS`}</a>{` interface. Those devices often do not have a CA Certificate Store with all common, active Root Certificates. This is why we provide the option to use these services in an `}<strong parentName="p">{`Insecure Mode`}</strong>{`. The encryption in this case is not less secure, as this name implies, but this mode only skips verifying if the camera actually is the device the Certificate Authority has issued the certificate to.`}</p>
    <p>{`If you have a chance to upload the root certificate to your device - e.g. an `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/"
      }}>{`Mosquitto MQTT Broker`}</a>{` - you can find the GoDaddy Root Certificate on the `}<a parentName="p" {...{
        "href": "https://certs.godaddy.com/repository"
      }}>{`GoDaddy website`}</a>{`. The correct certificate at the moment of writing is the `}<strong parentName="p">{`GoDaddy Certificate Bundles - G2`}</strong>{` `}<a parentName="p" {...{
        "href": "https://certs.godaddy.com/repository/gd_bundle-g2.crt"
      }}>{`gd_bundle-g2.crt`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`-----BEGIN CERTIFICATE-----
MIIE0DCCA7igAwIBAgIBBzANBgkqhkiG9w0BAQsFADCBgzELMAkGA1UEBhMCVVMx
EDAOBgNVBAgTB0FyaXpvbmExEzARBgNVBAcTClNjb3R0c2RhbGUxGjAYBgNVBAoT
EUdvRGFkZHkuY29tLCBJbmMuMTEwLwYDVQQDEyhHbyBEYWRkeSBSb290IENlcnRp
ZmljYXRlIEF1dGhvcml0eSAtIEcyMB4XDTExMDUwMzA3MDAwMFoXDTMxMDUwMzA3
MDAwMFowgbQxCzAJBgNVBAYTAlVTMRAwDgYDVQQIEwdBcml6b25hMRMwEQYDVQQH
EwpTY290dHNkYWxlMRowGAYDVQQKExFHb0RhZGR5LmNvbSwgSW5jLjEtMCsGA1UE
CxMkaHR0cDovL2NlcnRzLmdvZGFkZHkuY29tL3JlcG9zaXRvcnkvMTMwMQYDVQQD
EypHbyBEYWRkeSBTZWN1cmUgQ2VydGlmaWNhdGUgQXV0aG9yaXR5IC0gRzIwggEi
MA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC54MsQ1K92vdSTYuswZLiBCGzD
BNliF44v/z5lz4/OYuY8UhzaFkVLVat4a2ODYpDOD2lsmcgaFItMzEUz6ojcnqOv
K/6AYZ15V8TPLvQ/MDxdR/yaFrzDN5ZBUY4RS1T4KL7QjL7wMDge87Am+GZHY23e
cSZHjzhHU9FGHbTj3ADqRay9vHHZqm8A29vNMDp5T19MR/gd71vCxJ1gO7GyQ5HY
pDNO6rPWJ0+tJYqlxvTV0KaudAVkV4i1RFXULSo6Pvi4vekyCgKUZMQWOlDxSq7n
eTOvDCAHf+jfBDnCaQJsY1L6d8EbyHSHyLmTGFBUNUtpTrw700kuH9zB0lL7AgMB
AAGjggEaMIIBFjAPBgNVHRMBAf8EBTADAQH/MA4GA1UdDwEB/wQEAwIBBjAdBgNV
HQ4EFgQUQMK9J47MNIMwojPX+2yz8LQsgM4wHwYDVR0jBBgwFoAUOpqFBxBnKLbv
9r0FQW4gwZTaD94wNAYIKwYBBQUHAQEEKDAmMCQGCCsGAQUFBzABhhhodHRwOi8v
b2NzcC5nb2RhZGR5LmNvbS8wNQYDVR0fBC4wLDAqoCigJoYkaHR0cDovL2NybC5n
b2RhZGR5LmNvbS9nZHJvb3QtZzIuY3JsMEYGA1UdIAQ/MD0wOwYEVR0gADAzMDEG
CCsGAQUFBwIBFiVodHRwczovL2NlcnRzLmdvZGFkZHkuY29tL3JlcG9zaXRvcnkv
MA0GCSqGSIb3DQEBCwUAA4IBAQAIfmyTEMg4uJapkEv/oV9PBO9sPpyIBslQj6Zz
91cxG7685C/b+LrTW+C05+Z5Yg4MotdqY3MxtfWoSKQ7CC2iXZDXtHwlTxFWMMS2
RJ17LJ3lXubvDGGqv+QqG+6EnriDfcFDzkSnE3ANkR/0yBOtg2DZ2HKocyQetawi
DsoXiWJYRBuriSUBAA/NxBti21G00w9RKpv0vHP8ds42pM3Z2Czqrpv1KrKQ0U11
GIo/ikGQI31bS/6kA1ibRrLDYGCD+H1QQc7CoZDDu+8CL9IVVO5EFdkKrqeKM+2x
LXY2JtwE65/3YR8V3Idv7kaWKK2hJn0KCacuBKONvPi8BDAB
-----END CERTIFICATE-----
-----BEGIN CERTIFICATE-----
MIIDxTCCAq2gAwIBAgIBADANBgkqhkiG9w0BAQsFADCBgzELMAkGA1UEBhMCVVMx
EDAOBgNVBAgTB0FyaXpvbmExEzARBgNVBAcTClNjb3R0c2RhbGUxGjAYBgNVBAoT
EUdvRGFkZHkuY29tLCBJbmMuMTEwLwYDVQQDEyhHbyBEYWRkeSBSb290IENlcnRp
ZmljYXRlIEF1dGhvcml0eSAtIEcyMB4XDTA5MDkwMTAwMDAwMFoXDTM3MTIzMTIz
NTk1OVowgYMxCzAJBgNVBAYTAlVTMRAwDgYDVQQIEwdBcml6b25hMRMwEQYDVQQH
EwpTY290dHNkYWxlMRowGAYDVQQKExFHb0RhZGR5LmNvbSwgSW5jLjExMC8GA1UE
AxMoR28gRGFkZHkgUm9vdCBDZXJ0aWZpY2F0ZSBBdXRob3JpdHkgLSBHMjCCASIw
DQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAL9xYgjx+lk09xvJGKP3gElY6SKD
E6bFIEMBO4Tx5oVJnyfq9oQbTqC023CYxzIBsQU+B07u9PpPL1kwIuerGVZr4oAH
/PMWdYA5UXvl+TW2dE6pjYIT5LY/qQOD+qK+ihVqf94Lw7YZFAXK6sOoBJQ7Rnwy
DfMAZiLIjWltNowRGLfTshxgtDj6AozO091GB94KPutdfMh8+7ArU6SSYmlRJQVh
GkSBjCypQ5Yj36w6gZoOKcUcqeldHraenjAKOc7xiID7S13MMuyFYkMlNAJWJwGR
tDtwKj9useiciAF9n9T521NtYJ2/LOdYq7hfRvzOxBsDPAnrSTFcaUaz4EcCAwEA
AaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAOBgNVHQ8BAf8EBAMCAQYwHQYDVR0OBBYE
FDqahQcQZyi27/a9BUFuIMGU2g/eMA0GCSqGSIb3DQEBCwUAA4IBAQCZ21151fmX
WWcDYfF+OwYxdS2hII5PZYe096acvNjpL9DbWu7PdIxztDhC2gV7+AJ1uP2lsdeu
9tfeE8tTEH6KRtGX+rcuKxGrkLAngPnon1rpN5+r5N9ss4UXnT3ZJE95kTXWXwTr
gIOrmgIttRD02JDHBHNA7XIloKmf7J6raBKZV8aPEjoJpL1E/QYVN8Gb5DKj7Tjo
2GTzLH4U/ALqn83/B2gX2yKQOC16jdFU8WnjXzPKej17CuPKf1855eJ1usV2GDPO
LPAvTK33sefOT6jEm0pUBsV/fdUID+Ic/n4XuKxe9tQWskMJDE32p2u0mYRlynqI
4uJEvlz36hz1
-----END CERTIFICATE-----`}</code></pre></div>
    <p><strong parentName="p">{`You do not`}</strong>{` have to upload this certificate to your camera as it is already part of the internal CA store. But if your IoT service complains that it cannot verify the cameras certificate you can supply this root certificate. E.g. to your `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/"
      }}>{`Mosquitto MQTT Broker`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{` verifying the certificate can only work if you are connecting via your `}<a parentName="p" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Network/Remote_Access/"
        }}>{`INSTAR DDNS address`}</a>{`. When connecting through your local IP address you will have to activate the `}<strong parentName="p">{`Insecure Mode`}</strong>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "take-matters-into-your-own-hands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#take-matters-into-your-own-hands",
        "aria-label": "take matters into your own hands permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Take Matters into your own Hands`}</h2>
    <p>{`For local area networks (LAN's) it is often a bit tideous to get an CA certificate to work. But you can become your own authority - this is an interesting solution if you have a domain service set up, asigning domain names to all your local network devices (e.g. with an `}<strong parentName="p">{`AVM Firtzbox Internet Router`}</strong>{` you have the local `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fritz.box`}</code>{` domain that you can use).`}</p>
    <p><strong parentName="p">{`Ok, now things are going to become a little bit complicated`}</strong>{`. If you are not comfortable with using your computer terminal it will be better to just stick with the INSTAR DDNS certificate. It is perfectly secure to use.`}</p>
    <h3 {...{
      "id": "mosquitto-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mosquitto-broker",
        "aria-label": "mosquitto broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mosquitto Broker`}</h3>
    <p>{`As an example I want to create my own certificate to use with an external `}<strong parentName="p">{`Mosquitto Broker`}</strong>{` running on a `}<strong parentName="p">{`Debian Bullseye`}</strong>{` server - for example a Raspberry Pi. The broker will be set up according to the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/"
      }}>{`Mosquitto 2.0 with CA Certificate`}</a>{` guide using the certificates generated in the following steps.`}</p>
    <p><em parentName="p">{`Creating Self-Signed Certificates`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Client Requirements`}</p>
        <ul parentName="li">
          <li parentName="ul">{`A CA (certificate authority) certificate of the CA that has signed the server certificate on the Mosquitto Broker.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Broker Requirements`}</p>
        <ul parentName="li">
          <li parentName="ul">{`CA certificate of the CA that has signed the server certificate on the Mosquitto Broker.`}</li>
          <li parentName="ul">{`CA certificated server certificate.`}</li>
          <li parentName="ul">{`Server Private key for decryption.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Make sure that you have a fresh version of `}<strong parentName="p">{`OpenSSL`}</strong>{` installed:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl version
OpenSSL `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.1`}</span>{`.1l  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2021`}</span></code></pre></div>
    <p>{`Mosquitto already creates a directory for self-signed certificates - if it does not exists create it with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /etc/mosquitto/certs
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` mosquitto:mosquitto /etc/mosquitto/certs`}</code></pre></div>
    <h3 {...{
      "id": "ca-certificates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ca-certificates",
        "aria-label": "ca certificates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CA Certificates`}</h3>
    <p>{`Let's get started by generating a key:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /etc/mosquitto/certs
openssl genrsa -out ca.key `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span></code></pre></div>
    <p>{`Now we can continue using this key to sign our CA key and certificate.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Important`}</strong>{`: The `}<strong parentName="p">{`FQDN`}</strong>{` must not be the same as the server `}<strong parentName="p">{`FQDN`}</strong>{`, otherwise you might end up with SSL errors. On a local network using a AVM Fritzbox router every device receives both a hostname - for example my Mosquitto server identifies as debian11 - and a domain name `}<strong parentName="p">{`hostname + .fritz.box`}</strong>{`. So, for example, I am able to access the Mosquitto webserver via `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`http://debian11.fritz.box:8080/`}</code>{`.`}</p>
    </blockquote>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl req -new -x509 -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1825`}</span>{` -key ca.key -out ca.crt

You are about to be asked to enter information that will be incorporated
into your certificate request.
What you are about to enter is what is called a Distinguished Name or a DN.
There are quite a few fields but you can leave some blank
For some fields there will be a default value,
If you enter `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.'`}</span>{`, the field will be left blank.
-----
Country Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` letter code`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`AU`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
State or Province Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`full name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Some-State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Locality Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, city`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Organization Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, company`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Internet Widgits Pty Ltd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:INSTAR
Organizational Unit Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Common Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e.g. server FQDN or YOUR name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:debian11.fritz.box
Email Address `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:`}</code></pre></div>
    <p>{`Make sure that your Mosquitto Broker is allowed to read the generated file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` mosquitto:mosquitto ca.`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`crt,key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`*.cert`}</code>{` file that was generated here is the one we will later rename `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`*.pem`}</code>{` and upload inside the `}<strong parentName="p">{`Custom CA Store`}</strong>{` menu of our camera.`}</p>
    <h3 {...{
      "id": "server-certificates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#server-certificates",
        "aria-label": "server certificates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Certificates`}</h3>
    <p>{`Now need to generate our server certificates - the steps are identical. We start generating a key:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl genrsa -out server.key `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span></code></pre></div>
    <p>{`Now we can use this private key to sign our certificate:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Important`}</strong>{`: Here the `}<strong parentName="p">{`FQDN`}</strong>{` must be the hostname, otherwise you might end up with SSL errors. As mentioned above my server can be reached via the hostname `}<strong parentName="p">{`debian11`}</strong>{` - I can test this by visiting the mosquitto webserver `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`http://debian11:8080/`}</code>{`.`}</p>
    </blockquote>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl req -new -out server.csr -key server.key

You are about to be asked to enter information that will be incorporated
into your certificate request.
What you are about to enter is what is called a Distinguished Name or a DN.
There are quite a few fields but you can leave some blank
For some fields there will be a default value,
If you enter `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.'`}</span>{`, the field will be left blank.
-----
Country Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` letter code`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`AU`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
State or Province Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`full name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Some-State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Locality Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, city`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Organization Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, company`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Internet Widgits Pty Ltd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:INSTAR
Organizational Unit Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Common Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e.g. server FQDN or YOUR name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:debian11
Email Address `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:

Please enter the following `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'extra'`}</span>{` attributes
to be sent with your certificate request
A challenge password `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
An optional company name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl x509 -req -in server.csr -CA ca.crt -CAkey ca.key -CAcreateserial -out server.crt -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1825`}</span>{`

Signature ok
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`subject`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`C `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` AU, ST `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Some-State, O `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` INSTAR, CN `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` debian11
Getting CA Private Key`}</code></pre></div>
    <p>{`Again, we have to make sure that Mosquitto is allowed to read our files:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` mosquitto:mosquitto server.`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`csr,key,crt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And the certs directory should now contain the following files:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` mosquitto mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1956`}</span>{` Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:31 ca.crt
-rw------- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` mosquitto mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3243`}</span>{` Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:30 ca.key
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root      root        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`41`}</span>{` Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:37 ca.srl
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` mosquitto mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1834`}</span>{` Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:37 server.crt
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` mosquitto mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1659`}</span>{` Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:37 server.csr
-rw------- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` mosquitto mosquitto `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3243`}</span>{` Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:34 server.key`}</code></pre></div>
    <h3 {...{
      "id": "client-certificates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#client-certificates",
        "aria-label": "client certificates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Client Certificates`}</h3>
    <p>{`For more security, you can add client certificates, which need to be signed by the server. Generate client certificates for the MQTT clients. Important: `}<strong parentName="p">{`Don’t use the server name as FQDN`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl genrsa -out client.key `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{`
openssl req -out client.csr -key client.key -new`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`You are about to be asked to enter information that will be incorporated
into your certificate request.
What you are about to enter is what is called a Distinguished Name or a DN.
There are quite a few fields but you can leave some blank
For some fields there will be a default value,
If you enter `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.'`}</span>{`, the field will be left blank.
-----
Country Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` letter code`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`AU`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
State or Province Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`full name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Some-State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Locality Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, city`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Organization Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, company`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Internet Widgits Pty Ltd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:INSTAR
Organizational Unit Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
Common Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e.g. server FQDN or YOUR name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:mqtt.client
Email Address `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:

Please enter the following `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'extra'`}</span>{` attributes
to be sent with your certificate request
A challenge password `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
An optional company name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl x509 -req -in client.csr -CA ca.crt --CAkey ca.key --CAcreateserial -out client.crt -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1825`}</span>{`

Signature ok
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`subject`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`C `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` AU, ST `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Some-State, O `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` INSTAR, CN `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` mqtt.client
Getting CA Private Key`}</code></pre></div>
    <p>{`And again - set the correct permissions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` mosquitto:mosquitto client.`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`csr,crt,key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "connect-your-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connect-your-instar-ip-camera",
        "aria-label": "connect your instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect your INSTAR IP Camera`}</h3>
    <p>{`To connect your INSTAR camera you first have to do 2 things:`}</p>
    <ol>
      <li parentName="ol">{`Combine the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`client.key`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`client.crt`}</code>{` into a single file called `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`client.pem`}</code>{`:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`-----BEGIN RSA PRIVATE KEY-----
MIIJKAIBAAKCAgEAorc0ouM2Uh0pBlZ5IbCSonwOACUCPQ+FqWjhRl5FbAAke2iK

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.

l6hxaLG33DoTvYoEbjBEmLtsBAz4sdnTGi2z6HOYfMsqGjMehPJmr2XH/kA`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
-----END RSA PRIVATE KEY-----
-----BEGIN CERTIFICATE-----
MIIFIDCCAwgCFHLf6A9ycbmW6ExF+DBGE3T3qhFAMA0GCSqGSIb3DQEBCwUAMFAx

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`CVSpxYNxMG6gIpeIFrTogygOfdc`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
-----END CERTIFICATE-----`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Take the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ca.crt`}</code>{` and save it as `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ca.pem`}</code>{`.`}</li>
    </ol>
    <p>{`Now open your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/SSL_Certificate/"
      }}>{`SSL Certificate Menu`}</a>{`, add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`client.pem`}</code>{` as your custom certificate and enable it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/ecf19/1440p_WebUI_Network_CA_Certificate_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC20lEQVQ4y12SW08aQRiG+QtNmibURIKmyi4LIrumFw0VFU8IOOedmd1FPCAo0FpFq1LBWrWxV70ztSZNemnbC/9hMysen3wXc/Ps+858G4jH40Yspg0OmKmZfPPX5LufmcaP0bXzt1V/1vzxz5n6+fzOb7Dw4cXzZ8Hgy2AwGDAMw4jFIgOvXo/NlU7+ic6VPPxjt6/Ywe201dD2lXv0d/H0mld2R1NvxsbH0+l0QNf1aDSqaVpyOMFQwWFIMuTYSFAoKJQMSnUAauBsYSZdW6+enX07PTnpdDoBTdOi0aiu66Zp2TaXUgohpXQIZQhjjAnCGGGEMCGNr7DcWlleaTa3tza3GvVGV9Y0zTRNQghjjFJKCMFPIbTSQqWNSqW6v9/ab30qL5TuZcuyCCGu63pF7wYIIboFQggLWZDP1hv1duewudta9JaUrOu6Sk4mCSGe51FKVVO/LcaqAiFECCEdF0K0Xqt9Pjr62Go7zmJXjkQiSV8WQmCMVQ6AlDAhBOdcKDijFEBYq9UODo9WGtuzc0yt6mFtztWbSSFlUSLpZ/sghMrlMmNstbq2Wt8WC9WZWRiI+tw9mOu6fprgLoccIti9MEJoeXmZc55KpWKx+FAioevRrhyJRMykkh3HwRgDACDomgipdJtzZqtFJJPJnp6ekM+9fHPnpaUl27ZVFO6aNmOe5xWLRdfzmG1blhUKhfr7+8Ph8EPZpJSUFjwp+MMlqeVTyoWUjsOYknt7e/v6+rqyYRjqwUwzD0kW2DnI0OP/Q30C5gnMUQQeyYaPrmuxhHW6Xrju5C6bcxTfRWOEqZLXj/nOBat9sUzzUfJN7cSwWXHBXhlslObxXWlQgPlZVMjm3NW5xffzxYplPZA1n8HBQcs05xHNApIHxIcSBEh5R+xdks3vJzlwMT19nMubIyNPZZWcSEz5TN6RmZjMkSm6mkElZ3yynB53JjLxoaFQKKTUcPg/9DBC0gayzq0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/e4706/1440p_WebUI_Network_CA_Certificate_01.avif 230w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/d1af7/1440p_WebUI_Network_CA_Certificate_01.avif 460w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/7f308/1440p_WebUI_Network_CA_Certificate_01.avif 920w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/4ce31/1440p_WebUI_Network_CA_Certificate_01.avif 948w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/a0b58/1440p_WebUI_Network_CA_Certificate_01.webp 230w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/bc10c/1440p_WebUI_Network_CA_Certificate_01.webp 460w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/966d8/1440p_WebUI_Network_CA_Certificate_01.webp 920w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/af3d9/1440p_WebUI_Network_CA_Certificate_01.webp 948w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/81c8e/1440p_WebUI_Network_CA_Certificate_01.png 230w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/08a84/1440p_WebUI_Network_CA_Certificate_01.png 460w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/c0255/1440p_WebUI_Network_CA_Certificate_01.png 920w", "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/ecf19/1440p_WebUI_Network_CA_Certificate_01.png 948w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ccdcf5b9c0c0e63e182bfdb2bdaf3dac/c0255/1440p_WebUI_Network_CA_Certificate_01.png",
              "alt": "Connect your INSTAR IP Camera",
              "title": "Connect your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And secondly add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ca.pem`}</code>{` here in the `}<strong parentName="p">{`CA Custom Store`}</strong>{` menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0fa840c387c47c11db75ff4620301efe/47aef/1440p_WebUI_Network_CA_Certificate_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB1ElEQVQoz2WRS2/TQBRG/SvSsktaYhPGWCRC5dE8PZM4fkw8nhmPZ5y4DQmoFQrQJpUIUlshFqxYpKvuEBKwh3+I4rSqEJ+O7u7ovjTLsgAA5iPrQXkHjS/Ci9/O4md3/ss+/Qd4+oN/+tMcXTbr+9VarVAolEolzTRNsLZN/f5uX81Gy+tkvpKLKzG/EvNVXtck81W2vA7GZ0+f7b3Yb1SrT7a272kP8wAAdN1A7cZQEBFhyf6DBjHxfBc9b9Rhr6+GE+vxnrYxAQCGYSCEhJCUccbjNTGn7A6RyAAP2jaqtzucjzzM72Rd1yGEqVJpmiqlpJL0gFJKOeeU0izLZrOZ7wew2292IGOjMJRapVLZTJ7LiIqUcBlRxhijeRVCJImQgknBMMbdntuG3Vgc0mh4IwMAymXdc9DlG/FxilMRkYjSXM+7S3b4jr88wYSjnttBfSknnB9o4Da7ZX3Qt78tw+8f/KkiA5KLZEADh3CBT76Gi5VLEth1Gh17lB0pOb19Vb6zDZEfJQ5m0fpojFHCx2/Z7Mtw/P68Bz/7XgDtHcOo1aoMsziZ3Lxqs3Or1QoHGAd+4OfxXD95Fbw+p/L4DDlLFzvN5tZ2oVgsVsq6aVX/AmMMsF3q2/qDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fa840c387c47c11db75ff4620301efe/e4706/1440p_WebUI_Network_CA_Certificate_02.avif 230w", "/en/static/0fa840c387c47c11db75ff4620301efe/d1af7/1440p_WebUI_Network_CA_Certificate_02.avif 460w", "/en/static/0fa840c387c47c11db75ff4620301efe/7f308/1440p_WebUI_Network_CA_Certificate_02.avif 920w", "/en/static/0fa840c387c47c11db75ff4620301efe/29832/1440p_WebUI_Network_CA_Certificate_02.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0fa840c387c47c11db75ff4620301efe/a0b58/1440p_WebUI_Network_CA_Certificate_02.webp 230w", "/en/static/0fa840c387c47c11db75ff4620301efe/bc10c/1440p_WebUI_Network_CA_Certificate_02.webp 460w", "/en/static/0fa840c387c47c11db75ff4620301efe/966d8/1440p_WebUI_Network_CA_Certificate_02.webp 920w", "/en/static/0fa840c387c47c11db75ff4620301efe/0ca5b/1440p_WebUI_Network_CA_Certificate_02.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fa840c387c47c11db75ff4620301efe/81c8e/1440p_WebUI_Network_CA_Certificate_02.png 230w", "/en/static/0fa840c387c47c11db75ff4620301efe/08a84/1440p_WebUI_Network_CA_Certificate_02.png 460w", "/en/static/0fa840c387c47c11db75ff4620301efe/c0255/1440p_WebUI_Network_CA_Certificate_02.png 920w", "/en/static/0fa840c387c47c11db75ff4620301efe/47aef/1440p_WebUI_Network_CA_Certificate_02.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0fa840c387c47c11db75ff4620301efe/c0255/1440p_WebUI_Network_CA_Certificate_02.png",
              "alt": "Connect your INSTAR IP Camera",
              "title": "Connect your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the client to use your MQTT brokers Hostname instead of it's local IP to be able to activate the `}<strong parentName="p">{`Verify TLS Certificate`}</strong>{` option:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/170ebde9ca921339ecf35341c088aaab/78597/1440p_WebUI_Network_CA_Certificate_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeUlEQVQoz22S3W/SUBiH+SPM1ISAw0VaSoGR2TLRuKwFWkoL56vntO5DVzbZ2MaHG1MZw+1KvDDe6xJNNN4Z/0TTls059+S9OL14+nvPe95INpsVxQyfnJNKpHH8Sxv8VPrfld4NVT38Yb/7XXvWvjtza+b2nWg0Gkn7iNyDuUUVuMNvZPAZH30hR+f/l/PmfHX01fE6Va2s6bqiKJFUKiUIAs/z0kLeBiZDFrQ0UKuAWgWaFWBWwjMwyqBuWsjp7e9OPkzeTyaDwSAiCEI6neZ5XpYLGBMIEcKY2DaxKUTY/yIEY4KwfwYQbW697Pf6/X7f87ypzHGcJEkYY0qp53nNzc1ms/l8fZ0EUEptQhCxAXHb7d3hcDgcnaw5bnjnqcwYcxzHdV1GGfR7QKZp6rpeCdCqNR24nc7+8Wj86mjoEMdPFgQhmUxKkkQZY4zato0QCuVyuVwsFmVZlgsF6eHC08ePut3u2enp69EYYTdoWxAuk0MIITCwDcNQVVUJWFZLZQN0O523o9MXOwelUt1vW5jKMg2inUsZhn8IgADRFbC219re2Wj16MqWohr+U11MW6aUOg6zbfu6GVwDEYZXW4uLhVRKEMVMMslFeJ4XRZHjOFmWGPOj4V8VGlWjpJbCrpfVUrVu57KZaDR6L8CXw2R/YJRRSq/Gapq2tLT0JKBYLJZVJZ/Px+PxROL+7OzsVA4HhhACAMCrIH9n/EVByC9McrlcLB5LJBK+HK7npQyvUTeBpYO6YUCgQ2hhNJ/LxWIX8tV3vi6DBlxr49YYeod9E5xUrT2rkZnPxS9lPuAmGcFGHW0c0N4ntH02rjU+GtbA/Ef+A837Gtr5K6SuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/170ebde9ca921339ecf35341c088aaab/e4706/1440p_WebUI_Network_CA_Certificate_03.avif 230w", "/en/static/170ebde9ca921339ecf35341c088aaab/d1af7/1440p_WebUI_Network_CA_Certificate_03.avif 460w", "/en/static/170ebde9ca921339ecf35341c088aaab/7f308/1440p_WebUI_Network_CA_Certificate_03.avif 920w", "/en/static/170ebde9ca921339ecf35341c088aaab/3d505/1440p_WebUI_Network_CA_Certificate_03.avif 947w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/170ebde9ca921339ecf35341c088aaab/a0b58/1440p_WebUI_Network_CA_Certificate_03.webp 230w", "/en/static/170ebde9ca921339ecf35341c088aaab/bc10c/1440p_WebUI_Network_CA_Certificate_03.webp 460w", "/en/static/170ebde9ca921339ecf35341c088aaab/966d8/1440p_WebUI_Network_CA_Certificate_03.webp 920w", "/en/static/170ebde9ca921339ecf35341c088aaab/19b8a/1440p_WebUI_Network_CA_Certificate_03.webp 947w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/170ebde9ca921339ecf35341c088aaab/81c8e/1440p_WebUI_Network_CA_Certificate_03.png 230w", "/en/static/170ebde9ca921339ecf35341c088aaab/08a84/1440p_WebUI_Network_CA_Certificate_03.png 460w", "/en/static/170ebde9ca921339ecf35341c088aaab/c0255/1440p_WebUI_Network_CA_Certificate_03.png 920w", "/en/static/170ebde9ca921339ecf35341c088aaab/78597/1440p_WebUI_Network_CA_Certificate_03.png 947w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/170ebde9ca921339ecf35341c088aaab/c0255/1440p_WebUI_Network_CA_Certificate_03.png",
              "alt": "Connect your INSTAR IP Camera",
              "title": "Connect your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And make sure that your `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/"
      }}>{`Mosquitto Broker is set up to use the same certificates`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      